<template>
  <div class="read-pager">
    <Banner />
    <Navigate :pager="pager" :params="params" @change="changePager" />
    <div class="detail">
      <div class="title text-align-center">实验必读</div>
      <div class="sub-title text-align-center">更新于：{{ upTime || '--' }}  浏览人数：{{ viewNum }}</div>
      <div class="inner">
        <div class="name">硬件要求</div>
        <div class="cont" v-html="hardwareAsk.replace(/<[^>]+>/g, '')"></div>
        <div class="name">网络要求</div>
        <div class="cont" v-html="interAsk.replace(/<[^>]+>/g, '')"></div>
        <div class="name">用户操作系统要求</div>
        <div class="cont" v-html="systemAsk.replace(/<[^>]+>/g, '')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./components/banner";
import Navigate from "./components/navigate";
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  components: { Navigate, Banner },
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      upTime: '',
      viewNum: 0,
      hardwareAsk: '',
      interAsk: '',
      systemAsk: ''
    };
  },
  watch: {
    pager() {
      this.queryStudyDetail()
    },
    params() {
      this.queryStudyDetail()
    }
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.upTime = res.data.upTime
        this.viewNum = res.data.viewNum
        this.hardwareAsk = res.data.hardwareAsk
        this.interAsk = res.data.interAsk
        this.systemAsk = res.data.systemAsk
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.read-pager {
  background-color: #F4F5F8;
  .detail {
    width: 100%;
    max-width: 1200px;
    margin: 25px auto 50px;
    box-sizing: border-box;
    .title {
      height: 42px;
      line-height: 42px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .sub-title {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #234984;
      margin-top: 6px;
    }
    .inner {
      padding: 40px 60px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 20px;
      opacity: 0.86;
      margin-top: 30px;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .cont {
        font-size: 14px;
        color: #666666;
        line-height: 2;
        text-indent: 2em;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
